// Various helper functions that are likely used multiple times throughout the
// app.

// Takes a "date" in epoch seconds and puts it in the form:
// "dd month yyyy hh:mm:ss".
export function formatCreationDate(date) {
  // Times by 1000 as JavaScript works in milleseconds since the epoch.
  const parts = new Date(date * 1000).toString().split(' ');

  return parts[2] + ' ' + parts[1] + ' ' + parts[3] + ' ' + parts[4];
}

// Takes a "date" in epoch seconds and puts it in the form:
// "day, dd month yyyy".
export function formatExpiryDate(date) {
  // Times by 1000 as JavaScript works in milleseconds since the epoch.
  const parts = new Date(date * 1000).toString().split(' ');

  return parts[0] + ', ' + parts[2] + ' ' + parts[1] + ' ' + parts[3];
}
