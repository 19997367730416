import React from "react"
import Table from "./table"
import AddArchiveButton from "./addarchivebutton"
import { authenticatedLoginContext } from './authenticatedLoginContext'

class Homepage extends React.Component {
  render() {
    if (this.context.username === 'unidentified human') {
      return(<p>Loading...</p>);
    } else {
      return(
        <React.Fragment>
          <h3>My vaults</h3>
          <br />
          <p>Welcome, {this.context.username}!</p>
          { this.context.admin 
            ? <p>As you are an admin, all vaults in the system are listed below.</p>
            : <p>Your vaults are listed below.</p>
          }
          <p>Select a vault to manage it or click "Create new vault" to create another.</p>
          <Table />
        </React.Fragment>);
    }
  }
}

Homepage.contextType = authenticatedLoginContext;

export default Homepage
