import React from 'react'
import { Link } from 'gatsby'

class Nav extends React.Component {
  render() {
    return(
      <React.Fragment>
        <nav class='c-nav c-nav--main' role='navigation' id='Main-Navigation-2'>
        <ul class='c-nav__list c-nav__list--structural'>
          <li class='c-nav__item'><Link to='/'>My vaults</Link></li>
          <li class='c-nav__item'><Link to='/add/'>Create new vault</Link></li>
          <li class='c-nav__item'><Link to='/requests/'>Upload requests</Link></li>
          <li class='c-nav__item'><Link to='/faq/'>FAQ</Link></li>
          <li class='c-nav__item'><Link to='/deleted/'>Deleted vaults</Link></li>
        </ul>
      </nav>
    </React.Fragment>);
  }
}

export default Nav
