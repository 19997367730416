import React from "react"
import { Link } from "gatsby"

class LookingEmptyAlert extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      dismissed: false
    };

    this.dismiss = this.dismiss.bind(this);
  }

  dismiss() {
    this.setState({
      dismissed: true
    });
  }

  render() {
    const { dismissed } = this.state;

    if (dismissed) {
      return null;
    } else {
      return(
        <React.Fragment>
          <div class="c-alert c-alert--info" role="alert">
            <div class="c-alert__content">
              <p><strong>It's looking a bit empty round here...</strong></p>
              <p>Click below to add your first archive.</p>
              <p>Visit <Link to="/faq/">the FAQ page</Link> for basic queries.</p>
              <p>Contact itsupport@york.ac.uk for additional guidance.</p>
              <button class="c-btn c-btn--secondary c-btn--medium" onClick={this.dismiss}>Close</button>
            </div>
          </div>
        </React.Fragment>);
    }
  }
}

export default LookingEmptyAlert
