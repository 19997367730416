import React from "react"
import Layout from "../components/layout"
import Homepage from "../components/homepage"

class Index extends React.Component {
  render() {
    return(
      <React.Fragment>
        <Layout>
          <Homepage />
        </Layout>
      </React.Fragment>
    );
  }
}

export default Index;
