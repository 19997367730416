import React from "react"

class Spinner extends React.Component {
  render() {
    return(
      <React.Fragment>
        <br />
	<p>Loading...</p>
        <br />
      </React.Fragment>);
  }
}

export default Spinner
