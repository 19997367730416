import React from "react"
import { navigate } from "gatsby"

class AddArchiveButton extends React.Component {
  constructor(props) {
    super(props);

    this.redirect = this.redirect.bind(this);
  }

  async redirect() {
    navigate("/add/");
  }

  render() {
    return(
      <React.Fragment>
        <button class="c-btn c-btn--success c-btn--medium" onClick={this.redirect}>Create new vault</button>
      </React.Fragment>);
  }
}

export default AddArchiveButton
