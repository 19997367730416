import React from "react"
import LookingEmptyAlert from "./lookingemptyalert"
import { Link } from "gatsby"
import Spinner from "./spinner"
import { Auth } from "aws-amplify"
import { authenticatedLoginContext } from './authenticatedLoginContext'
import { formatCreationDate } from '../helpers/helpers'

class Table extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      error: null,
      loaded: false,
      items: []
    };
  }

  async componentDidMount() {
    Auth.currentSession()
      .then(async data => {

        // If the authenticated user is an admin, fetch all vaults, else just
        // fetch the vaults that the authenticate user owns.
        if (this.context.admin === true) {
          var url = `${process.env.GATSBY_API_URL}/vaults`
        } else {
          url = `${process.env.GATSBY_API_URL}/vaults?username=${this.context.username}`
        }

        var res = await fetch(
          url,
          {
            method: 'GET',
            mode: 'cors',
            headers: new Headers({
              'Authorization': 'Bearer ' + data.idToken.jwtToken
            })
          }
        );

        const all_vaults = await res.json();

        // This will keep vaults whose "deleted" value is "undefined" too, which is fine.
        var non_deleted_vaults = all_vaults.filter(vault => vault.deleted !== true)

        this.setState({
          loaded: true,
          items: non_deleted_vaults,
          username: this.context.username
        });

      })
      .catch(err => console.log(err));
  }

  render() {
    const { error, loaded, items } = this.state;

    if (error) {
      return <div>Error</div>;
    } else if (!loaded) {
      return <Spinner />;
    } else {
      var count = items.length;

      if (count === 0) {
	return <LookingEmptyAlert />;
      } else {
	return(
	  <React.Fragment>
	    <table>
	      <thead>
		<tr>
		  <th>Vault</th>
		  <th>Description</th>
		  <th>Created</th>
		</tr>
	      </thead>
	      <tbody>
		{
		  items.map((vault, index) => (
		    <tr key={index}>
		      <td key="0">
                        <Link
                          to="/details/"
                          state={{details: vault}}
                          style={{ textDecoration: 'none' }}>
                          {vault.title}
                        </Link>
                      </td>
		      <td key="1">
                        <Link
                          to="/details/"
                          state={{details: vault}}
                          style={{ textDecoration: 'none' }}>
                          {vault.description}
                        </Link>
                      </td>
                      <td key="2">
                        <Link
                          to="/details/"
                          state={{details: vault}}
                          style={{ textDecoration: 'none' }}>
                          {formatCreationDate(vault.creationDateSeconds)}
                        </Link>
                      </td>
		    </tr>
		  ))
		}
	      </tbody>
	    </table>
	  </React.Fragment>);
      }
    }
  }
}

Table.contextType = authenticatedLoginContext;

export default Table
